export const environment = {
    production: true,
    apiUrl: window.location.protocol+"//apptrackerdev-api.ienergy.halliburton.com/api/v1/",
    signalRUrl: window.location.protocol + "//apptrackerdev-api.ienergy.halliburton.com/",
  
    
    CLIENT_ID : '0oa136fmneb750M1q0h8',
    ISSUER : 'https://myappstest.halliburton.com/oauth2/aus136ax8vmrfZKes0h8',
    LOGIN_REDIRECT_URI : window.location.origin + '/login/callback',
    // LOGOUT_REDIRECT_URI : window.location.origin + '/logout',
    LOGOUT_REDIRECT_URI : window.location.origin,
  
    PageRefreshInterval : 5*60*1000 /* in min */
  };
  